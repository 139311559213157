.DragDrop {
    width: 100%;
    height: 100%;
    background-color: #DEDEDF;
    border-radius: 10px;

    position: relative;
    overflow: hidden;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DragDrop-File {
    width: 100%;
    height: 100%;
    /*border: 1px solid black;*/
    /*border-radius: 10px;*/

    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: 0.12s ease-in;

}


.DragDrop-File-Dragging {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 10px;

    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: 0.12s ease-in;

    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}
