.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: "Georgia", serif;
    font-size: 14px;
    padding: 15px;
}

.RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: "Hoefler Text", "Georgia", serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
    font-size: 16px;
    padding: 20px;
}

.RichEditor-controls {
    font-family: "Helvetica", sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
}

.RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.RichEditor-activeButton {
    color: #5890ff;
}

::placeholder {
    color: #ccc;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #ccc;
}

h1, h2, h3, h4, h5 {
    margin-top: 0 !important;
    margin-bottom: 0!important;
}
figure {
    margin-top: 0!important;
    margin-bottom: 0!important;
    margin-left: 0!important;
    margin-right: 0!important;
}

.box01 {
    width: 500px;
    height: 300px;
    position: relative;
    background: url(https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2F0%2F10_237388e3cf-9abb-4e89-b40d-6407fdd66801.jpg?alt=media&token=43573f30-ec98-4ab2-a0ed-b91b0987f493);
    background-size: cover;
}

.box01::before {
    content: "";
    opacity: 0.5;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #000;
}

.box01 h1 {
    color: #fff;
    text-align: center;
    line-height: 300px;
    position: relative;
}


